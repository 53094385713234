var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AImage',{staticClass:"height-full bg-black",staticStyle:{"background-color":"#404142"},attrs:{"src":"https://pilotcity-headshots.s3.us-west-1.amazonaws.com/authbackground.jpg","height":"100%"}},[_c('Loading',{attrs:{"callback":_vm.login,"linear-loader":""}},[_c('m-welcome-label',{attrs:{"auth-type":'login',"is-icon-show":_vm.isBackIconVisible}}),(_vm.isOtpSent)?_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.$route.query.otpSent)?_c('v-alert',{staticClass:"signup__alert mb-6 login__emailalign",staticStyle:{"font-size":"16px"},attrs:{"type":"success"}},[_vm._v(" We have detected that account with this number already exists, please enter the One-Time Password verification code which we have sent to your phone. ")]):_vm._e(),_c('v-form',{staticClass:"signup__firstname"},[_c('div',[_c('AOtpInput',{model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1),_c('div',{staticClass:"d-flex justify-center mt-6 flex-column align-center"},[_c('a',{attrs:{"href":"https://www.pilotcity.com/"}},[_c('img',{staticClass:"nav__logo2",attrs:{"src":require("@/assets/Pilotcity_logo.png"),"max-height":"50px"}})]),_c('AButton',{attrs:{"styling":{
              class: 'mt-6',
              color: 'blue',
              outlined: false,
              dark: true,
              rounded: true,
              xLarge: true,
              depressed: true,
              width: '100%'
            },"type":"submit","disabled":!_vm.isValidOtp,"loading":_vm.verifying},on:{"click":_vm.verifyOtp}},[_vm._v(" Login ")]),(_vm.otpMsg)?_c('v-alert',{staticClass:"signup__alert mt-3",staticStyle:{"font-size":"16px"},attrs:{"type":_vm.type}},[_vm._v(" "+_vm._s(_vm.otpMsg)+" ")]):_vm._e()],1)])],1):_c('div',{staticClass:"login__inputs d-flex flex-column align-center"},[(_vm.verificationMsg)?_c('v-alert',{staticClass:"signup__alert mb-3 login__emailalign",staticStyle:{"font-size":"16px"},attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.verificationMsg)+" ")]):_vm._e(),(_vm.isEmail)?_c('v-form',{staticClass:"login__emailalign"},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [(_vm.isEmail)?_c('validation-provider',{ref:"provider",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('ATextInput',{ref:"emailInput",attrs:{"styling":{
                class: 'login__input mb-4',
                outlined: true,
                rounded: true,
                xLarge: true,
                depressed: true,
                fullWidth: true,
                label: 'Email',
                placeholder: 'Email',
                color: 'white'
              },"single-line":"","dark":"","error-messages":errors,"lowercase":true},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}):_vm._e(),(_vm.isEmail)?_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ATextInput',{ref:"passwordInput",attrs:{"styling":{
                class: 'login__input',
                outlined: true,
                rounded: true,
                xLarge: true,
                depressed: true,
                fullWidth: true,
                label: 'Password',
                placeholder: 'Password',
                color: 'white',
                toggle: true,
                appendIcon: _vm.show1 ? 'mdi-eye' : 'mdi-eye-off'
              },"single-line":"","dark":"","error-messagees":errors,"type":_vm.show1 ? 'text' : 'password',"icon-click":_vm.togglePassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"white--text text-right"},'div',attrs,false),on),[_c('i',[_c('a',{staticClass:"login__forgotlink white--text"},[_vm._v(" Forgot Password")])])])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"login__dialog text-center",attrs:{"dark":""}},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-text-field',{attrs:{"x-large":"","rounded":"","outlined":"","label":"Enter your email"},model:{value:(_vm.resetEmail),callback:function ($$v) {_vm.resetEmail=$$v},expression:"resetEmail"}}),_c('v-btn',{staticClass:"send-reset-pwd-btn",attrs:{"x-large":"","depressed":"","rounded":"","outlined":""},on:{"click":_vm.sendResetPasswordEmail}},[_vm._v(" Send Reset Password Email "),(_vm.isSending)?_c('v-progress-circular',{staticClass:"ml-2 setup-adk-interview-generate",attrs:{"size":"20","indeterminate":"","color":"white"}}):_vm._e()],1),(_vm.msg)?_c('v-alert',{staticClass:"login__alert",attrs:{"type":_vm.type}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)])],1)]}}],null,true)}):_vm._e(),_c('AButton',{ref:"loginBtn",attrs:{"styling":{
              class: 'mt-6',
              color: 'blue',
              outlined: false,
              dark: true,
              rounded: true,
              xLarge: true,
              depressed: true,
              width: '100%'
            },"type":"submit","ripple":false,"disabled":invalid || _vm.signingIn || _vm.checking,"loading":_vm.signingIn || _vm.checking},on:{"click":_vm.checkUserStatus}},[_vm._v("Login")]),(_vm.msg)?_c('v-alert',{staticClass:"signup__alert mt-4",staticStyle:{"font-size":"16px"},attrs:{"type":_vm.type}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()]}}],null,false,1847621855)})],1):(_vm.isEmailOtp)?_c('v-form',{staticClass:"login__emailalign"},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('validation-provider',{ref:"provider",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('ATextInput',{ref:"emailInput",attrs:{"styling":{
                class: 'login__input mb-4',
                outlined: true,
                rounded: true,
                xLarge: true,
                fullWidth: true,
                label: 'Email',
                placeholder: 'Email',
                color: 'white'
              },"single-line":"","dark":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('AButton',{ref:"loginBtn",attrs:{"styling":{
              class: 'mt-6',
              color: 'blue',
              outlined: false,
              dark: true,
              rounded: true,
              xLarge: true,
              depressed: true,
              width: '100%'
            },"type":"submit","disabled":invalid,"loading":_vm.signingIn,"ripple":false},on:{"click":_vm.signIn}},[_vm._v(" Continue ")]),(_vm.msg)?_c('v-alert',{staticClass:"login__alert",attrs:{"type":_vm.type}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()]}}])})],1):_vm._e(),(_vm.isPhone)?_c('v-form',[_c('validation-observer',{staticClass:"login__emailalign",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"slim":"","rules":"required"}},[_c('ATeleInput',{staticClass:"mb-4",attrs:{"is-rounded":true},on:{"get":_vm.getValidation},model:{value:(_vm.phoneState.phone_number),callback:function ($$v) {_vm.$set(_vm.phoneState, "phone_number", $$v)},expression:"phoneState.phone_number"}})],1),_c('AButton',{attrs:{"styling":{
              class: 'mt-6',
              color: 'blue',
              outlined: false,
              dark: true,
              rounded: true,
              xLarge: true,
              depressed: true,
              width: '100%'
            },"type":"submit","disabled":invalid || !_vm.isPhoneValid,"loading":_vm.signingIn},on:{"click":_vm.signIn}},[_vm._v(" Continue ")]),(_vm.msg)?_c('v-alert',{staticClass:"signup__alert mt-4",staticStyle:{"font-size":"16px"},attrs:{"type":_vm.type}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e(),(_vm.$route.query.forcedLogout)?_c('v-alert',{staticClass:"signup__alert mt-4 login__emailalign",staticStyle:{"font-size":"16px"},attrs:{"type":"error"}},[_vm._v(" Due to security reasons, you've been logged out from PilotCity. Please login again with your current password in order to continue. ")]):_vm._e()]}}],null,false,224483323)})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-4 flex-column align-center"},[_c('div',{staticClass:"login__newaccount text-center mb-6"},[(_vm.programNameParams || _vm.isDialog)?_c('div',{on:{"click":function($event){return _vm.showSignUpModal()}}},[_c('a',{staticClass:"login__signuplink white--text text-center text-decoration-underline"},[_vm._v(" No account yet? Signup.")])]):_c('div',[_c('router-link',{attrs:{"to":{ name: 'signup', params: { page: _vm.$route.params.page } }}},[_c('a',{staticClass:"login__signuplink white--text text-center"},[_vm._v(" No account yet? Signup.")])])],1)]),_c('a',{attrs:{"href":"https://www.pilotcity.com/"}},[_c('img',{staticClass:"nav__logo2",attrs:{"src":require("@/assets/Pilotcity_logo.png"),"max-height":"50px"}})]),_c('div',{staticClass:"or mt-3"},[_vm._v("OR")]),(_vm.isEmail || _vm.isEmailOtp)?_c('AButton',{attrs:{"styling":{
            class: 'mt-6',
            color: 'blue',
            outlined: false,
            dark: true,
            rounded: true,
            xLarge: true,
            depressed: true,
            width: '100%'
          }},on:{"click":_vm.displayMobileForm}},[_vm._v(" Continue with phone ")]):_c('AButton',{attrs:{"styling":{
            class: 'mt-6',
            color: 'blue',
            outlined: false,
            dark: true,
            rounded: true,
            xLarge: true,
            depressed: true,
            width: '100%'
          }},on:{"click":_vm.displayEmailForm}},[_vm._v(" Login with Email ")]),(_vm.isEmail)?_c('AButton',{attrs:{"styling":{
            class: 'mt-6',
            color: 'blue',
            outlined: false,
            dark: true,
            rounded: true,
            xLarge: true,
            depressed: true,
            width: '100%'
          }},on:{"click":_vm.displayEmailOtpForm}},[_vm._v(" Login with Email otp ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }